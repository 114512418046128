import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export default function SupportPage() {
  return (
    <Layout>
      <SEO title="Support" />
      <hr />
      <h2>PACING TIMER</h2>
      <p>
        If you experience crashes or errors with this app, please{' '}
        <a href="mailto:support@learningobjective.net">contact me</a> so I can
        fix the issue.
      </p>

      <p>
        If you have suggestions on how to make Pacing Timer better for your
        situation, I'm on Twitter as{' '}
        <a href="https://twitter.com/LearningObj">@LearningObj</a>
      </p>

      <p>
        If you would like an alternate color scheme added in a future update, go
        into settings and vote for your preferred color.
      </p>

      <h2>Frequently Asked Questions</h2>

      <h3>
        <p>Why isn't this free?</p>
      </h3>
      <p>
        Developing mobile apps takes time and effort. Your support helps make
        further updates better, as well as other classroom apps I have planned
        for the future.
      </p>

      <h3>
        <p>Do I have to setup the sections of my lesson each time I run it?</p>
      </h3>

      <p>
        No. Your section title, order, and durations are saved as you setup the
        lesson. You currently cannot save multiple lessons and switch between
        them, but I'm considering it for a future update. If this feature would
        be helpful to you, let me know on twitter.
      </p>
    </Layout>
  );
}
